import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import { withServerSideProps } from 'sholdi-hocs/withServerSideProps'
import { isSuperAdminPage } from 'sholdi-hocs/withServerSideProps/utils'

const MainLayout = dynamic(() => import('../components/templates/MainLayout'))
const ShopAdminLayout = dynamic(() => import('../components/templates/AdminLayout'))
const SuperAdminLayout = dynamic(() => import('../components/templates/SuperAdminLayout'))
const ErrorPage = dynamic(() => import('../components/pageviews/ErrorPage'))

const layouts = {
  Main: MainLayout,
  Admin: ShopAdminLayout,
  SuperAdmin: SuperAdminLayout,
}

const Error = ({ statusCode, layout = 'Main' }) => {
  const Layout = layouts[layout]
  return (
    <Layout>
      <ErrorPage errorCode={statusCode} layout={layout} />
    </Layout>
  )
}

Error.propTypes = {
  statusCode: PropTypes.number,
  layout: PropTypes.string,
}

export const getServerSideProps = withServerSideProps({
  getProps: async ctx => {
    const {
      resolvedUrl,
      res: { statusCode },
    } = ctx

    const layout = isSuperAdminPage(resolvedUrl)
      ? 'SuperAdmin'
      : resolvedUrl.startsWith('/admin')
        ? 'Admin'
        : 'Main'

    return { statusCode, layout }
  },
})

export default Error
